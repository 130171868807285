import { Storage } from "ui/common/types/storage";
import { FamilyState } from "./appSlice";

// store keys
const OPT_OUT_CALL_WARNING_KEY = "optout_call_warn";

export class LegacyFamilyStorage {
  constructor(private readonly storage: Storage) {}

  public async read(): Promise<FamilyState> {
    const optOutOfCallWarning = await this.getOptOutOfCallWarning();

    return {
      optOutOfCallWarning,
    };
  }

  private async getOptOutOfCallWarning(): Promise<boolean> {
    return (await this.storage.getItem(OPT_OUT_CALL_WARNING_KEY)) === "true";
  }
}
