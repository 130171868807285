import { AppState, Plugins } from "@capacitor/core";
import { useCallback, useEffect } from "react";

export const useAppState = (onChange: (state: AppState) => void): void => {
  const callback = useCallback(onChange, []);

  useEffect(() => {
    const subscription = Plugins.App.addListener("appStateChange", callback);

    return () => subscription.remove();
  }, [callback]);
};
