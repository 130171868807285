import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FamilyState {
  optOutOfCallWarning: boolean;
}

const initialFamilyState: FamilyState = {
  optOutOfCallWarning: false,
};

export const familyAppSlice = createSlice({
  name: "app",
  initialState: initialFamilyState,
  reducers: {
    setOptOutCallWarning(state, action: PayloadAction<boolean>) {
      state.optOutOfCallWarning = action.payload;
    },
  },
});

export const { setOptOutCallWarning } = familyAppSlice.actions;
