import { DateTime } from "luxon";
import { Plugins, FilesystemDirectory, Capacitor, GetUriResult, Browser } from "@capacitor/core";
import { FileOpener } from "@ionic-native/file-opener";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import i18n from "i18n";
import { Media } from "@capacitor-community/media";
import { AppEnvironments } from "environment";
import { AttachmentKind } from "generated/graphql";
import * as graphql from "generated/graphql";
import { Color } from "ui/senior/components/colors";
import { CustomError, ErrorType, captureException, normalizeMaybeError } from "ui/common/lib/error_handling";
import semver from "semver";

export enum TodayFormat {
  HourMinute = 1,
  TodayString,
}

export type GroupInfo = {
  id: string;
  supportedFeatures: graphql.Maybe<graphql.Maybe<graphql.AppFeature>[]> | undefined;
  relatedSeniorEncryptionMode: graphql.Maybe<graphql.EncryptionMode> | undefined;
  memberDevices: graphql.Maybe<
    {
      __typename?: "MemberDevice" | undefined;
    } & Pick<graphql.MemberDevice, "memberId" | "deviceId" | "publicKey">
  >[];
  senderKeysList: {
    __typename?: "SenderKey" | undefined;
  } & Pick<graphql.SenderKey, "id" | "deviceId" | "userId" | "keyHash">[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = (...data: any[]): void => {
  // eslint-disable-next-line no-console
  console.error(data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logInfo = (...data: any[]): void => {
  // eslint-disable-next-line no-console
  console.info(data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logWarning = (...data: any[]): void => {
  // eslint-disable-next-line no-console
  console.warn(data);
};

export const getTimeAgo = (timestamp: string, language: string, todayFormat = TodayFormat.HourMinute): string => {
  const dt = DateTime.fromISO(timestamp).setLocale(language);
  const diff = Math.floor(Math.abs(dt.diffNow("day").days));

  let res;
  if (diff === 0 && todayFormat === TodayFormat.HourMinute) res = dt.toFormat("HH:mm");
  else if (diff <= 7) res = dt.toRelativeCalendar({ unit: "days" }) || "";
  else res = dt.toLocaleString({ month: "short", day: "numeric" });

  return res;
};

type Token = { sub: string; email?: string };

export const parseToken = (token: string): Token => {
  return JSON.parse(atob(token.split(".")[1]));
};

export const getUserFromToken = (token: string): { id: string } => {
  const payload = parseToken(token);
  return { id: payload.sub };
};

export const readFileAsync = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") resolve(reader.result);
      else reject(new Error(`Invalid type of result: ${typeof reader.result}`));
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const readFileContentFromUrl = async (url: string): Promise<string> => {
  const response = await fetch(url);
  if (!response.ok)
    throw new CustomError(ErrorType.ResponseError, "Error in response when reading content from url", {
      url,
      response,
    });
  const blob = await response.blob();
  return readFileAsync(blob);
};

export const fileExists = async (path: string, directory?: FilesystemDirectory): Promise<boolean> => {
  if (!Capacitor.isNative) return false;

  try {
    const stat = await Plugins.Filesystem.stat({ path, directory });
    return stat.size > 0;
  } catch {
    return false;
  }
};

export const resolveFileSrc = async (path: string, directory: FilesystemDirectory): Promise<string> => {
  if (!(await fileExists(path, directory))) throw Error("file-not-found");
  const { uri } = await Plugins.Filesystem.getUri({ path, directory });
  return Capacitor.convertFileSrc(uri);
};

export const resolveMediaFileSrc = async (path: string, directory: FilesystemDirectory): Promise<string> => {
  if (!(await fileExists(path, directory))) throw Error("file-not-found");
  const { uri } = await Plugins.Filesystem.getUri({ path, directory });
  return Capacitor.convertFileSrc(uri);
};

export const saveToFile = async (
  fileContent: string,
  path: string,
  directory: FilesystemDirectory
): Promise<string> => {
  await Plugins.Filesystem.writeFile({
    data: fileContent,
    directory,
    path: `${path}.part`,
    recursive: true,
  });
  await Plugins.Filesystem.rename({
    directory,
    from: `${path}.part`,
    to: path,
  });

  return resolveFileSrc(path, directory);
};

export const download = async (url: string, path: string, directory = FilesystemDirectory.Data): Promise<string> => {
  const fileContent = await readFileContentFromUrl(url);
  return saveToFile(fileContent, path, directory);
};

class PermissionError extends Error {}

export const downloadAppend = async (
  url: string,
  filename: string,
  abortSignal?: AbortSignal,
  progressFunc?: (p: number) => void,
  directory = FilesystemDirectory.Data,
  retryStrategy: "forever" | "limited" = "forever"
): Promise<void> => {
  const NUM_BUFF_FETCH = 12;
  const RETRY_TIMEOUT = 100;
  const MAX_RETRIES = 10;
  if (progressFunc) progressFunc(0);

  // FIXME remove linter exception
  // eslint-disable-next-line no-constant-condition
  let attempts = 0;
  while (true) {
    attempts += 1;
    try {
      const response = await fetch(url, { signal: abortSignal });
      // 0.01 so it doesn't get added to the hash of current progress
      if (progressFunc) progressFunc(0.01);

      if (!response.ok) {
        if (response.status === 403) throw new PermissionError("Permission denied when fetching media");
        throw new CustomError(ErrorType.ResponseError, "Error in response when downloading", { url, response });
      }

      const contentLength = response.headers.get("Content-Length");

      const totalBytes = contentLength && parseInt(contentLength, 10);
      if (!totalBytes)
        throw new CustomError(ErrorType.ResponseError, "Couldn't retrieve content-length in response headers", {
          headers: response.headers,
        });

      let transferredBytes = 0;
      const { body } = response;

      if (!body) throw new CustomError(ErrorType.ResponseError, "Body is null", { response });
      const reader = body.getReader();
      let buffers: Uint8Array[] = [];

      // FIXME remove linter exception
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (value) buffers.push(value);

        // Write to file when buffer size > 1e6 or the file has been downloaded (done=true)
        if ((done && buffers.length) || buffers.length === NUM_BUFF_FETCH) {
          await Plugins.Filesystem.appendFile({
            data: await readFileAsync(new Blob(buffers)),
            directory,
            path: `${filename}.part`,
          });
          buffers = [];
        }

        if (done) {
          // finished without errors
          try {
            await Plugins.Filesystem.rename({
              directory,
              from: `${filename}.part`,
              to: filename,
            });
          } catch (e) {
            if (!(await fileExists(filename, directory))) throw e;
          }
          if (progressFunc) progressFunc(1.0);
          return;
        }

        if (value && progressFunc) {
          transferredBytes += value.length;
          // progress will be set to 1.0 (and thus completed), when the file part is renamed
          progressFunc((0.99 * transferredBytes) / totalBytes);
        }
      }
    } catch (e) {
      const isPermissionDenied = e instanceof PermissionError;
      const error = normalizeMaybeError(e);
      const skipRetry =
        (!error.message.includes("Failed to fetch") && !isPermissionDenied) ||
        (retryStrategy === "limited" && attempts > MAX_RETRIES);
      if (skipRetry) throw e;
      else {
        // retry
        if (progressFunc) progressFunc(0.0001);
        await new Promise(resolve => setTimeout(resolve, RETRY_TIMEOUT));
      }
    }
  }
};

export const downloadText = async (
  url: string,
  path: string,
  retryStrategy: "forever" | "limited" = "forever"
): Promise<string> => {
  try {
    await downloadAppend(url, path, undefined, undefined, FilesystemDirectory.Data, retryStrategy);
    const { uri } = await Plugins.Filesystem.getUri({ path, directory: FilesystemDirectory.Data });
    return Capacitor.convertFileSrc(uri);
  } catch (err) {
    throw new CustomError(ErrorType.ResponseError, "Error in downloadText", { url, err });
  }
};

export const generateRandomNumber = (digits: number): number => {
  const min = 10 ** (digits - 1);
  const max = 10 ** digits - 1;
  return Math.floor(((Math.random() + Math.random()) / 2) * (max - min + 1)) + min;
};

function handleCreateDirError(path: string, error: Error): void {
  if (error.message !== "Directory exists") {
    captureException(error, { path });
  }
}

export const readDir = async (path: string, directory: FilesystemDirectory): Promise<string[]> => {
  try {
    const { files } = await Plugins.Filesystem.readdir({ path, directory });
    return files;
  } catch {
    // throws if path doesn't exist
  }

  try {
    await Plugins.Filesystem.mkdir({ path, directory });
  } catch (e) {
    const error = normalizeMaybeError(e);
    handleCreateDirError(path, error);
  }
  return [];
};

export const getUriFromPath = (path: string): Promise<GetUriResult> => {
  return Plugins.Filesystem.getUri({
    directory: FilesystemDirectory.Data,
    path,
  });
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const downloadFileTransfer = async (
  url: string,
  fileName: string,
  progressFunc: (p: number) => void
): Promise<void> => {
  progressFunc(0);

  try {
    await Plugins.Media4CarePlugin.downloadFile({ url, path: fileName });

    progressFunc(1.0);
  } catch (e) {
    throw new CustomError(ErrorType.UnknownError, "downloadFileTransfer failed", { e });
  }
};

export const openPDF = async (filename: string): Promise<void> => {
  const { uri } = await Plugins.Filesystem.getUri({
    directory: FilesystemDirectory.Data,
    path: filename,
  });
  await FileOpener.open(uri, "application/pdf");
};

export const downloadPDF = async (url: string, title: string): Promise<string> => {
  const filename = `${title}.pdf`;
  const ctrl = new AbortController();
  await downloadAppend(url, filename, ctrl.signal);
  return filename;
};

export const normalizeToUUIDFormat = (value: string): string => {
  const UUID_WITH_HASHES_LEN = 36;
  const UUID_WITHOUT_HASHES_LEN = 32;
  if (value.length === UUID_WITH_HASHES_LEN) return value.toLowerCase();
  const uuid = value.padStart(UUID_WITHOUT_HASHES_LEN, "0");
  return [uuid.substr(0, 8), uuid.substr(8, 4), uuid.substr(12, 4), uuid.substr(16, 4), uuid.substr(20)]
    .join("-")
    .toLowerCase();
};

export const isDeviceMobile = (): boolean => {
  const { userAgent } = navigator;
  const pattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
  return pattern.exec(userAgent) !== null;
};

// Capacitor does not support iOS inApp browser text color customization
export const toolbarColor = Capacitor.platform === "android" ? Color.primary : undefined;

export const openBrowser = (url: string): void => {
  if (Capacitor.platform === "ios" || url.includes("youtube") || url.includes("youtu.be"))
    Plugins.Browser.open({ url, toolbarColor });
  else
    InAppBrowser.create(url, "_self", {
      location: "yes",
      lefttoright: "yes",
      hidenavigationbuttons: "yes",
      hideurlbar: "yes",
      toolbarcolor: toolbarColor,
      closebuttoncolor: "#ffffff",
    });
};

export const getAlbumName = (): string => i18n.t("gallery-album");

const media = new Media();

export const getIOSAlbumIdentifier = async (): Promise<string | undefined> => {
  try {
    const albums = await media.getAlbums();
    return albums.albums.find(a => a.name === getAlbumName())?.identifier;
  } catch (err) {
    // eslint-disable-next-line no-console
    logError("couldn't get album", err);
  }
  return undefined;
};

function getMediaFolder(kind: string): string {
  const folder = kind === AttachmentKind.Aac ? "Audio" : getAlbumName();
  return Capacitor.platform === "ios" ? `${folder}` : `Media4Care/${folder}`;
}

export function getMediaPath(id: string, kind: string): string {
  const filename = `${id}.${kind.toLowerCase()}`;
  return `${getMediaFolder(kind)}/${filename}`;
}

export async function createAlbumDirectory(directory = FilesystemDirectory.Data, kind: string): Promise<void> {
  const path = getMediaFolder(kind);
  try {
    const dirExists = await fileExists(path, directory);
    if (!dirExists) await Plugins.Filesystem.mkdir({ path, directory, recursive: true });
  } catch (e) {
    const error = normalizeMaybeError(e);
    if (!error.message.includes("Current directory does already exist")) captureException(error);
  }
}

export const createPublicAlbum = async (): Promise<string | undefined> => {
  if (Capacitor.platform === "ios") {
    let albumIdentifier = await getIOSAlbumIdentifier();

    if (!albumIdentifier) {
      try {
        await media.createAlbum({ name: getAlbumName() });
        albumIdentifier = await getIOSAlbumIdentifier();
      } catch (err) {
        // eslint-disable-next-line no-console
        logError("couldn't create album", err);
      }
    }
    return albumIdentifier;
  }

  await createAlbumDirectory(FilesystemDirectory.ExternalStorage, AttachmentKind.Jpg);
  return undefined;
};

export const getExtension = (text: string): string => {
  // eslint-disable-next-line no-bitwise
  return text.substr((~-text.lastIndexOf(".") >>> 0) + 2);
};

export const saveAttachmentInIOSGallery = async (path: string, directory: FilesystemDirectory): Promise<void> => {
  const { uri } = await Plugins.Filesystem.getUri({ path, directory });
  const ext = getExtension(uri);
  const albumIdentifier = await getIOSAlbumIdentifier();
  const options = { path: uri, album: albumIdentifier };
  if (albumIdentifier) {
    try {
      if (ext === "mp4") await media.saveVideo(options);
      else if (["jpg", "png", "gif"].includes(ext)) await media.savePhoto(options);
      else throw new CustomError(ErrorType.TypeError, "invalid media extension");
    } catch (err) {
      // eslint-disable-next-line no-console
      logError("couldn't save image in gallery", err);
    }
  }
  return Promise.resolve();
};

export const saveAttachmentInAndroidGallery = async (path: string, directory: FilesystemDirectory): Promise<void> => {
  await Plugins.Filesystem.copy({
    from: path,
    to: path,
    directory,
    toDirectory: FilesystemDirectory.ExternalStorage,
  });
};

export const getChromeVersion = (): string | undefined => {
  const groups = /Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/.exec(navigator.userAgent);
  if (groups == null || groups.length !== 5) return undefined;
  return groups.slice(1, groups.length).join(".");
};

export const getMediaKind = (kind: string): AttachmentKind => {
  switch (kind.toUpperCase()) {
    case "AAC":
      return AttachmentKind.Aac;
    case "JPEG":
      return AttachmentKind.Jpg;
    case "JPG":
      return AttachmentKind.Jpg;
    case "GIF":
      return AttachmentKind.Gif;
    case "PNG":
      return AttachmentKind.Png;
    case "VIDEO/MP4":
    case "MP4":
      return AttachmentKind.Mp4;
    default:
      throw new CustomError(ErrorType.ResponseError, `Unknown kind ${kind}`);
  }
};

export const getImageDimensions = (url: string): Promise<{ height: number; width: number }> =>
  new Promise(function(resolve) {
    const image = new Image();

    image.onload = function() {
      resolve({
        width: image.width,
        height: image.height,
      });
    };

    image.src = url;
  });

export const deleteFile = async (path: string, directory?: FilesystemDirectory): Promise<void> => {
  try {
    await Plugins.Filesystem.deleteFile({ path, directory });
  } catch (e) {
    const error = normalizeMaybeError(e);
    if (!error.message.includes("File does not exist")) captureException(error);
  }
};

export const getThumbnailUri = (videoUri: string): string => videoUri.replace(".mp4", ".jpg");

// FIXME: use this function in old components
export const openUrlInBrowser = (url: string): void => {
  Browser.open({ url, toolbarColor });
};

export const isNative = (): boolean => Boolean(Capacitor.isNative);

export const compareAppVersions = (ver1: string, ver2: string, appEnv: AppEnvironments): number => {
  const versionPatterns = {
    local: /(\d{4}-\d{2}-\d{2})-(\d+)-\w+$/,
    dev: /(\d{4}-\d{2}-\d{2})-(\d+)-\w+$/,
    stage: /(\d{1,2}(?:.\d{0,2})*)-beta(\d{0,2})$/,
    prod: /(\d{1,2}(?:.\d{0,2})*)$/,
  };
  const r1 = versionPatterns[appEnv].exec(ver1);
  const r2 = versionPatterns[appEnv].exec(ver2);
  if (r1 && r2) {
    if (appEnv === AppEnvironments.Dev || appEnv === AppEnvironments.Local) {
      const d1 = new Date(r1[1]);
      const d2 = new Date(r2[1]);
      const t1 = parseInt(r1[2], 10);
      const t2 = parseInt(r2[2], 10);
      if (d1.getTime() === d2.getTime()) {
        if (t1 === t2) return 0;
        if (t1 > t2) return 1;
        return -1;
      }
      if (d1 > d2) return 1;
      return -1;
    }
    if (appEnv === AppEnvironments.Stage) {
      const compareResult = semver.compare(r1[1], r2[1]);
      if (compareResult === 0) {
        // compare number after beta
        const b1 = parseInt(r1[2], 10) || 0;
        const b2 = parseInt(r2[2], 10) || 0;
        if (b1 === b2) return 0;
        if (b1 > b2) return 1;
        return -1;
      }
      return compareResult;
    }
    return semver.compare(r1[1], r2[1]);
  }
  throw new CustomError(ErrorType.ValueError, "Version must be of appropriate format", { ver1, ver2, env: appEnv });
};

export function formatLicenseDate(date?: string): string {
  return date ? DateTime.fromISO(date).toFormat("dd.MM.yyyy") : "";
}

export function formatDateTime(date?: string): string {
  return date ? DateTime.fromISO(date).toFormat("dd.MM.yyyy hh:mm:ss") : "";
}

export type LicenseStatus = "active" | "inactive" | "expired";

export function getLicenseStatus(active: boolean, endDate?: string): LicenseStatus {
  if (endDate && DateTime.fromISO(endDate) < DateTime.local()) return "expired";

  return active ? "active" : "inactive";
}

export const sleep = async (delayTimeInMS: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, delayTimeInMS));
