import { useCallback, useReducer } from "react";
import { CallContextType } from "context";
import { useCommonAppSelector } from "ui/common/state/store";
import { VideocallEventType } from "generated/graphql";
import { logInfo } from "ui/common/lib/utils";

export type DoneStatus =
  | "cancelled"
  | "declined"
  | "missed"
  | "busy"
  | "rejected"
  | "placing-timeout"
  | "ringing-timeout"
  | "completed"
  | "error";

export enum CallActionType {
  UI_CALL = "UI_CALL",
  UI_ACCEPT = "UI_ACCEPT",
  UI_CANCEL = "UI_CANCEL",
  UI_REJECT = "UI_REJECT",
  UI_HANGUP = "UI_HANGUP",
  UI_CLOSE = "UI_CLOSE",
  CALL = "CALL",
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  RINGING = "RINGING",
  BUSY = "BUSY",
  TIMEOUT = "TIMEOUT",
  ERROR = "ERROR",
}

type CallAction = {
  type: CallActionType;
  payload?: {
    chatId: string;
    callerId?: string;
  };
};

type TransitionConfig = {
  transition?: CallState;
  emit?: VideocallEventType;
};

type CallSpecs = {
  initial: CallState;
  states: Record<CallState["state"], { [P in CallActionType]?: TransitionConfig }>;
};

type SpecsResolver = (state: CallState, event: CallAction) => CallSpecs;

export type CallState =
  | { state: "Idle" }
  | { state: "Done"; chatId: string; status: DoneStatus }
  | { state: "Placing"; chatId: string }
  | { state: "RRinging"; chatId: string }
  | { state: "LRinging"; chatId: string }
  | { state: "Talking"; chatId: string };

export type MachineState = {
  value: CallState;
  event?: CallAction;
  emit?: VideocallEventType;
};

export const buildMachineReducer = (spec: SpecsResolver) => (
  current: MachineState,
  event: CallAction
): MachineState => {
  const stateTransitions = spec(current.value, event).states[current.value.state];

  if (stateTransitions === undefined) {
    throw new Error(`No transitions defined for ${current.value}`);
  }

  const next = stateTransitions[event.type];

  if (next === undefined) {
    logInfo(`Ignored transition for event ${event.type} in state ${current.value}`);
    return current;
  }

  return {
    value: next.transition || current.value,
    event,
    emit: next.emit,
  };
};

export const initialMachineState: MachineState = { value: { state: "Idle" } };

export const useStateMachine = (spec: SpecsResolver): [MachineState, React.Dispatch<CallAction>] => {
  return useReducer(buildMachineReducer(spec), initialMachineState);
};

export function isCallActionType(event: string): event is CallActionType {
  return Object.keys(CallActionType).includes(event);
}

function handleCall(current: CallState, event: CallAction): TransitionConfig {
  if (current.state !== "Idle" && current.chatId === event.payload?.chatId)
    return { transition: { state: "Talking", chatId: current.chatId }, emit: VideocallEventType.Accept };
  return { emit: VideocallEventType.Busy };
}

export const callSpecs = (current: CallState, event: CallAction): CallSpecs => {
  let chatId = "";

  // Only UI_CLOSE event does not have a payload and does not use chatId
  if (current.state === "Idle" || current.state === "Done") chatId = event.payload?.chatId || "";
  else chatId = current.chatId;

  return {
    initial: { state: "Idle" },
    states: {
      Idle: {
        UI_CALL: { transition: { state: "Placing", chatId }, emit: VideocallEventType.Call },
        CALL: { transition: { state: "LRinging", chatId }, emit: VideocallEventType.Ringing },
      },
      Placing: {
        UI_CANCEL: { transition: { state: "Done", chatId, status: "cancelled" }, emit: VideocallEventType.Reject },
        CALL: handleCall(current, event),
        RINGING: { transition: { state: "RRinging", chatId } },
        ACCEPT: { transition: { state: "Talking", chatId }, emit: VideocallEventType.Accept },
        REJECT: { transition: { state: "Done", chatId, status: "rejected" } },
        BUSY: { transition: { state: "Done", chatId, status: "busy" } },
        TIMEOUT: { transition: { state: "Done", chatId, status: "placing-timeout" }, emit: VideocallEventType.Reject },
        ERROR: { transition: { state: "Done", chatId, status: "error" } },
      },
      RRinging: {
        UI_CANCEL: { transition: { state: "Done", chatId, status: "cancelled" }, emit: VideocallEventType.Reject },
        CALL: handleCall(current, event),
        ACCEPT: { transition: { state: "Talking", chatId }, emit: VideocallEventType.Accept },
        REJECT: { transition: { state: "Done", chatId, status: "rejected" } },
        BUSY: { transition: { state: "Done", chatId, status: "busy" } },
        TIMEOUT: { transition: { state: "Done", chatId, status: "ringing-timeout" }, emit: VideocallEventType.Reject },
        ERROR: { transition: { state: "Done", chatId, status: "error" } },
      },
      LRinging: {
        UI_ACCEPT: { transition: { state: "Talking", chatId }, emit: VideocallEventType.Accept },
        UI_REJECT: { transition: { state: "Done", chatId, status: "declined" }, emit: VideocallEventType.Reject },
        CALL: event.payload?.chatId !== chatId ? { emit: VideocallEventType.Busy } : {},
        REJECT: { transition: { state: "Done", chatId, status: "missed" } },
        TIMEOUT: { transition: { state: "Done", chatId, status: "missed" }, emit: VideocallEventType.Reject },
        ERROR: { transition: { state: "Done", chatId, status: "error" } },
      },
      Talking: {
        UI_HANGUP: { transition: { state: "Done", chatId, status: "completed" }, emit: VideocallEventType.Reject },
        CALL: { emit: VideocallEventType.Busy },
        REJECT: { transition: { state: "Done", chatId, status: "completed" } },
      },
      Done: {
        UI_CLOSE: { transition: { state: "Idle" } },
        UI_CALL: { transition: { state: "Placing", chatId }, emit: VideocallEventType.Call },
        CALL: { transition: { state: "LRinging", chatId }, emit: VideocallEventType.Ringing },
      },
    },
  };
};

export const useCallStateMachine = (): { context: CallContextType } => {
  const { userId } = useCommonAppSelector();
  const [current, sendEvent] = useStateMachine(callSpecs);

  const state = current.value.state !== "Idle" ? current.value : { chatId: "" };

  // Will be used as useEffect dependency
  const error = useCallback(() => {
    sendEvent({ type: CallActionType.ERROR });
  }, [sendEvent]);

  const hangup = useCallback(() => {
    sendEvent({ type: CallActionType.UI_HANGUP, payload: { chatId: state.chatId } });
  }, [sendEvent, state.chatId]);

  return {
    context: {
      machine: current,
      // userId is null when logged out
      call(chatId: string, callerId = userId || "") {
        sendEvent({ type: CallActionType.UI_CALL, payload: { chatId, callerId } });
      },
      cancel(): void {
        sendEvent({ type: CallActionType.UI_CANCEL, payload: { chatId: state.chatId } });
      },
      accept(): void {
        sendEvent({ type: CallActionType.UI_ACCEPT, payload: { chatId: state.chatId } });
      },
      reject(): void {
        sendEvent({ type: CallActionType.UI_REJECT, payload: { chatId: state.chatId } });
      },
      hangup,
      timeout(): void {
        sendEvent({ type: CallActionType.TIMEOUT, payload: { chatId: state.chatId } });
      },
      error,
      close(): void {
        sendEvent({ type: CallActionType.UI_CLOSE });
      },
      syncLastEvent(type: CallActionType, chatId: string, callerId: string) {
        sendEvent({ type, payload: { chatId, callerId } });
      },
      subscribe(subscriptionData): void {
        if (subscriptionData.data?.newVideocallEvent?.videocallEvent) {
          const { event, groupId, senderId } = subscriptionData.data.newVideocallEvent.videocallEvent;

          if (isCallActionType(event)) {
            sendEvent({ type: event, payload: { chatId: groupId, callerId: senderId } });
          }
        }
      },
    },
  };
};
