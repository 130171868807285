import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { PersistGate } from "redux-persist/integration/react";
import { initApolloCache } from "ui/common/lib/client";
import { Capacitor, Plugins } from "@capacitor/core";
import { env } from "environment";
import { setupStorage } from "ui/common/lib/storage";
import { getSeniorStoreAndPersistor } from "ui/senior/state/store";
import { getFamilyStoreAndPersistor } from "ui/family/state/store";
import { CapacitorPathParser } from "ui/common/lib/capacitorPathParser";
import { CapacitorStorageAdapter } from "ui/common/lib/capacitorStorageAdapter";
import { StoreMigrator as FamilyStoreMigrator } from "ui/family/state/storeMigrator";
import { StoreMigrator as SeniorStoreMigrator } from "ui/senior/state/storeMigrator";
import "./i18n";
import App from "./App";
import { Platform } from "ui/common/types/platform";
import { PersistorProvider } from "ui/common/lib/persistor";

const storage = new CapacitorStorageAdapter();
const parser = new CapacitorPathParser();
const platform = Capacitor.getPlatform() as Platform;
const migrator =
  env.APP_KIND === "senior"
    ? new SeniorStoreMigrator(storage, parser, platform)
    : new FamilyStoreMigrator(storage, platform);

(async () => {
  await setupStorage();
  /*
    FIXME: improve naming of migrator
    migrator doesn't not only migrate but also sets up initial app state
    e.g: mediaPath
  */
  await migrator.migrate();
  const { store, persistor } =
    env.APP_KIND === "senior"
      ? getSeniorStoreAndPersistor(storage, parser)
      : getFamilyStoreAndPersistor(storage, parser);
  const cache = await initApolloCache(storage);

  Plugins.SplashScreen.hide();
  ReactDOM.render(
    <PersistorProvider persistor={persistor}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App cache={cache} />
        </PersistGate>
      </Provider>
    </PersistorProvider>,
    document.getElementById("root")
  );
  defineCustomElements(window);
})();
