export const SETTINGS_KEY = "222";

export const playstoreLink = "https://play.google.com/store/apps/details?id=com.media4care.family";
export const appstoreLink = "https://apps.apple.com/us/app/media4care-familienportal/id1511751700?ls=1";

export const cmsEnvironments = {
  local: {
    cmsUrl: "http://localhost:1337",
    cmsAdminUrl: "http://localhost:1337",
    bucketUrl: "http://localhost:9000/cms-releases",
  },
  dev: {
    cmsUrl: "https://cms-dev.m4c.app",
    cmsAdminUrl: "https://cms-admin-dev.m4c.app",
    bucketUrl: "https://m4c-cms-development.s3.eu-central-1.amazonaws.com",
  },
  prod: {
    cmsUrl: "https://cms-prod.m4c.app",
    cmsAdminUrl: "https://cms-admin-prod.m4c.app",
    bucketUrl: "https://m4c-cms-production.s3.eu-central-1.amazonaws.com",
  },
};

export type AvailableCmsEnvironments = keyof typeof cmsEnvironments;

export const EncryptionFAQLink = "https://www.media4care.de/faq/sind-das-tablet-und-die-benutzerdaten-sicher/";
