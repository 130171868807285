import { Region, Flavour } from "context";
import {
  initialSeniorState,
  ContentUpdateStatus,
  UsageMode,
  ContentModes,
  SeniorState,
  NameSetupStatus,
  SettingsConfig,
  SettingConfig,
  SettingOptionDefaults,
  SettingOptions,
} from "./appSlice";
import { CommonState } from "ui/common/state/appSlice";
import { cmsEnvironments, AvailableCmsEnvironments } from "config";
import { Storage } from "ui/common/types/storage";
import { PathParser } from "ui/common/types/fileSystem";
import { PERSISTOR_KEY } from "./store";
import { LegacyCommonStorage } from "ui/common/state/legacyCommonStorage";
import { Platform } from "ui/common/types/platform";
import { env } from "environment";
import { captureException, CustomError, ErrorType } from "ui/common/lib/error_handling";

// store keys
const SETUP_COMPLETE_KEY = "isSetupCompleted";
const REGION_KEY = "region";
const CONTENT_UPDATE_STATUS_KEY = "contentUpdateStatus";
const FLAVOUR_KEY = "flavour";
const CMS_ENV_KEY = "cms_env";
const USAGE_MODE_KEY = "usage_mode";
const CONTENT_GROUP_KEY = "content_group";
const CONTENT_MODE_KEY = "content_mode";
const SHOW_WALKTHROUGH_KEY = "showWalkthrough";
const TABLET_COMPATIBLE_KEY = "tabletCompatible";
const CONTENT_GROUP_SETUP_STATUS_KEY = "content_group_status";
const CONTENT_VERSION_KEY = "CONTENT_VER";
const SELECTED_CONTENT_VERSION_KEY = "selected_content_version";
const SKIP_SDCARD_KEY = "skip_sdcard_check";
const NAME_SETUP_STATUS_KEY = "nameSetupStatus";
const OVERRIDE_LICENSE_KEY = "override_license";
const LEGALPROMPTSHOWN_KEY = "legal_prompt_shown";
const VERSION_KEY = "version";
const TRIAL_START_TIMESTAMP_KEY = "trial_start_timestamp";
const LAST_LICENSE_CHECK_TIMESTAMP_KEY = "trial_last_license_check_timestamp";

const FLAVOUR_MISMATCH_PATCH_KEY = "flavour_mismatch_patch";

const SettingOptionKeys: { [key in SettingOptions]: string } = {
  appSettings: "app_enabled",
  automaticVideoCall: "internet_enabled",
  internet: "internet_enabled",
  videoVisit: "video_visit",
};

const REDUX_PERSIST_KEY = `persist:${PERSISTOR_KEY}`;

const isContentUpdateStatus = (value: unknown): value is ContentUpdateStatus => {
  return value === "idle" || value === "ready" || value === "inprogress";
};

export class StoreMigrator {
  constructor(
    private readonly storage: Storage,
    private readonly parser: PathParser,
    private readonly platform: Platform
  ) {}

  private async readStateFromLegacyStorage(): Promise<{ app: SeniorState; common: CommonState }> {
    const setupCompleted = await this.getSetupCompleted();
    const showWalkthrough = await this.getShowWalkthrough();
    const tabletCompatible = await this.getTabletCompatible();
    const region = await this.getRegion();
    const contentUpdateStatus = await this.getContentUpdateStatus();
    const softwareOnly = this.getSoftwareOnly();
    const flavour = await this.getFlavour();
    const cmsEnvironment = await this.getCmsEnvironment();
    const usageMode = await this.getUsageMode();
    const contentGroup = await this.getContentGroup();
    const contentMode = await this.getContentMode();
    const contentGroupSetupStatus = await this.getContentGroupSetupStatus();
    const installedContentVersion = await this.getInstalledContentVersion();
    const selectedContentVersionInEasterEgg = await this.getSelectedContentVersionInEasterEgg();
    const skipSdCard = await this.getSkipSdCard();
    const nameSetupStatus = await this.getNameSetupStatus();
    const overrideLicensing = await this.getOverrideLicensing();
    const legalPromptShown = await this.getLegalPromptShown();
    const storedVersion = await this.getStoredVersion();
    const trialStartedOn = await this.getTrialStartedOn();
    const licenseCheckedOn = await this.getLicenseCheckedOn();
    const settingsConfig = await this.getSettingsConfig();

    return {
      app: {
        setupCompleted,
        region,
        contentUpdateStatus,
        showWalkthrough,
        softwareOnly,
        tabletCompatible,
        flavour,
        cmsEnvironment,
        isBusy: false,
        showLicenseReminder: false,
        usageMode,
        contentGroup,
        contentMode,
        contentGroupSetupStatus,
        installedContentVersion,
        selectedContentVersionInEasterEgg,
        skipSdCard,
        nameSetupStatus,
        overrideLicensing,
        legalPromptShown,
        storedVersion,
        trialStartedOn,
        licenseCheckedOn,
        settingsConfig,
        existingUserLicenseActivationInProgress: false,
      },
      common: await new LegacyCommonStorage(this.storage, this.platform).read(),
    };
  }

  async migrate(): Promise<void> {
    const state = await this.storage.getItem(REDUX_PERSIST_KEY);
    const hasMigratedToReduxPersist = state !== null;

    if (hasMigratedToReduxPersist) {
      /*
        patch (re-migration) is required again only for non-licensing builds
        if stored flavour and env->flavour are different
        This bug occured because of overriding caregiver flavour (non redux persist) 5.2.0
        with b2b flavour (redux persist) 5.4.0 through mdm
        5.4.2 is a hotfix for these wrongly updated apps
      */
      const requiresPatch =
        (await this.storage.getItem(FLAVOUR_MISMATCH_PATCH_KEY)) === null && env.RELEASE === "5.4.2" && !!env.FLAVOUR;
      if (!requiresPatch) return;

      const storedState = JSON.parse(state);

      if ("app" in storedState) {
        const appState = JSON.parse(storedState.app);
        if ("flavour" in appState) {
          const flavour = appState.flavour;
          if (flavour === env.FLAVOUR) return;
          else {
            // continue to migrate
          }
        } else {
          captureException(new CustomError(ErrorType.IntegrityError, "Corrupted State", { storedState }));
        }
      } else {
        captureException(new CustomError(ErrorType.IntegrityError, "Corrupted State", { storedState }));
      }
    }

    const legacyState = await this.readStateFromLegacyStorage();
    /*
      Why keys are stringified?
      https://github.com/rt2zz/redux-persist/issues/489#issuecomment-336928988
    */
    const reduxPersistState = {
      app: JSON.stringify(legacyState.app),
      common: JSON.stringify(legacyState.common),
    };

    await this.storage.setItem(REDUX_PERSIST_KEY, JSON.stringify(reduxPersistState));
    await this.storage.setItem(FLAVOUR_MISMATCH_PATCH_KEY, "applied");
  }

  private async getSetupCompleted(): Promise<boolean> {
    // FIXME: handle in permisions instead of state init
    return initialSeniorState.setupCompleted || (await this.storage.getItem(SETUP_COMPLETE_KEY)) === "true";
  }

  private async getShowWalkthrough(): Promise<boolean> {
    const walkthroughValue = await this.storage.getItem(SHOW_WALKTHROUGH_KEY);
    return !walkthroughValue || walkthroughValue === "true";
  }

  private async getTabletCompatible(): Promise<boolean> {
    return (await this.storage.getItem(TABLET_COMPATIBLE_KEY)) === "true";
  }

  private async getRegion(): Promise<Region> {
    return ((await this.storage.getItem(REGION_KEY)) as Region) || initialSeniorState.region;
  }

  private async getContentUpdateStatus(): Promise<ContentUpdateStatus> {
    const storedValue = await this.storage.getItem(CONTENT_UPDATE_STATUS_KEY);
    return (isContentUpdateStatus(storedValue) && storedValue) || initialSeniorState.contentUpdateStatus;
  }

  private getSoftwareOnly(): boolean {
    return initialSeniorState.softwareOnly;
  }

  private async getFlavour(): Promise<Flavour | null> {
    const flavourValue = await this.storage.getItem(FLAVOUR_KEY);
    return (flavourValue && (flavourValue as Flavour)) || initialSeniorState.flavour;
  }

  private async getCmsEnvironment(): Promise<AvailableCmsEnvironments> {
    const cmsEnvValue = await this.storage.getItem(CMS_ENV_KEY);
    if (cmsEnvValue && Object.keys(cmsEnvironments).includes(cmsEnvValue)) {
      return cmsEnvValue as AvailableCmsEnvironments;
    } else {
      return initialSeniorState.cmsEnvironment;
    }
  }

  private async getUsageMode(): Promise<UsageMode> {
    const usageModeValue = await this.storage.getItem(USAGE_MODE_KEY);
    return UsageMode[usageModeValue as keyof typeof UsageMode] || initialSeniorState.usageMode;
  }

  private async getContentGroup(): Promise<string | null> {
    const contentGroupValue = await this.storage.getItem(CONTENT_GROUP_KEY);
    return contentGroupValue || initialSeniorState.contentGroup;
  }

  private async getContentMode(): Promise<ContentModes> {
    const contentModeValue = await this.storage.getItem(CONTENT_MODE_KEY);
    return ContentModes[contentModeValue as keyof typeof ContentModes] || initialSeniorState.contentMode;
  }

  private async getContentGroupSetupStatus(): Promise<"complete" | undefined> {
    const value = await this.storage.getItem(CONTENT_GROUP_SETUP_STATUS_KEY);
    return value === "complete" ? "complete" : undefined;
  }

  private async getInstalledContentVersion(): Promise<number | undefined> {
    const value = await this.storage.getItem(CONTENT_VERSION_KEY);
    return parseInt(value || "", 10) || undefined;
  }

  private async getSelectedContentVersionInEasterEgg(): Promise<number | undefined> {
    const value = await this.storage.getItem(SELECTED_CONTENT_VERSION_KEY);
    const parsed = parseInt(value || "", 10);
    return !isNaN(parsed) ? parsed : undefined;
  }

  private async getSkipSdCard(): Promise<boolean> {
    return (await this.storage.getItem(SKIP_SDCARD_KEY)) === "y";
  }

  private async getNameSetupStatus(): Promise<NameSetupStatus> {
    const value = await this.storage.getItem(NAME_SETUP_STATUS_KEY);
    if (value && (value === "inprogress" || value === "done")) return value;
    return undefined;
  }

  private async getOverrideLicensing(): Promise<boolean> {
    return (await this.storage.getItem(OVERRIDE_LICENSE_KEY)) === "y";
  }

  private async getLegalPromptShown(): Promise<boolean> {
    return (await this.storage.getItem(LEGALPROMPTSHOWN_KEY)) === "y";
  }

  private async getStoredVersion(): Promise<string | undefined> {
    return (await this.storage.getItem(VERSION_KEY)) || undefined;
  }

  private async getTrialStartedOn(): Promise<string | undefined> {
    return (await this.storage.getItem(TRIAL_START_TIMESTAMP_KEY)) || undefined;
  }

  private async getLicenseCheckedOn(): Promise<string | undefined> {
    return (await this.storage.getItem(LAST_LICENSE_CHECK_TIMESTAMP_KEY)) || undefined;
  }

  private getLastTimeAskedPassword = async (option: SettingOptions): Promise<number | undefined> => {
    const value = await this.storage.getItem(`pwd.lastTimeAsked.${SettingOptionKeys[option]}`);
    return parseInt(value || "0", 10);
  };

  private getIgnorePassword = async (option: SettingOptions): Promise<number | undefined> => {
    const value = await this.storage.getItem(`pwd.ignore.${SettingOptionKeys[option]}`);
    return parseInt(value || "0", 10);
  };

  private getSettingEnabled = async (option: SettingOptions): Promise<boolean> => {
    const value = await this.storage.getItem(SettingOptionKeys[option]);
    if (value && value.length) return value === "true";
    return Boolean(SettingOptionDefaults[option]);
  };

  private getSettingPassword = async (option: SettingOptions): Promise<string | undefined> => {
    return (await this.storage.getItem(`pwd.${SettingOptionKeys[option]}`)) || undefined;
  };

  private async getSettingConfig(setting: SettingOptions): Promise<SettingConfig> {
    return {
      password: {
        lastAskedOn: await this.getLastTimeAskedPassword(setting),
        ignoreDuration: await this.getIgnorePassword(setting),
        value: await this.getSettingPassword(setting),
      },
      enabled: await this.getSettingEnabled(setting),
    };
  }

  private async getSettingsConfig(): Promise<SettingsConfig> {
    return {
      appSettings: await this.getSettingConfig("appSettings"),
      automaticVideoCall: await this.getSettingConfig("automaticVideoCall"),
      internet: await this.getSettingConfig("internet"),
      videoVisit: await this.getSettingConfig("videoVisit"),
    };
  }
}
