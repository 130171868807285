import { Capacitor } from "@capacitor/core";
import { AnalyticsEvent, Media4CarePlugin } from "media4care-native";

export const trackScreen = (event: AnalyticsEvent): void => {
  if (Capacitor.isNative) Media4CarePlugin.trackScreen(event);
};

export const trackEvent = (event: AnalyticsEvent): void => {
  if (Capacitor.isNative) Media4CarePlugin.trackEvent(event);
};

export enum AnalyticsEventName {
  SessionStarted = "Session started",
  SessionEnded = "Session ended",
  AudioStarted = "Audio started",
  AudioPaused = "Audio paused",
  AudioResumed = "Audio resumed",
  AudioEnded = "Audio ended",
  VideoPlaybackStarted = "Video playback started",
  VideoPlaybackPaused = "Video playback paused",
  VideoPlaybackResumed = "Video playback resumed",
  VideoPlaybackEnded = "Video playback ended",
  Logout = "Logout",
  MessageHideKeyboard = "Message hide keyboard",
  MessageOpenCamera = "Message open camera",
  NotificationModalAnswer = "Notification modal answer",
  NotificationModalNext = "Notification modal next",
  NotificationModalClose = "Notification modal close",
  Search = "Search",
  OnboardingOpenWifiSettings = "Onboarding open wifi settings",
  OpenWifiSettings = "Open android wifi settings",
  OpenDisplaySettings = "Open android display settings",
  VideocallAutomaticAnswer = "Videocall automatic answer",
  MigrateStoragePlugin = "Migrate Storage Plugin",
  OpenWebsite = "Open Website",
  LicenseFAQOpen = "Licence FAQ opened from popup",
  LicenseActivationPostpone = "Licence activation postponed from popup",
  LicenseActivate = "Licence activation started from popup",
}
