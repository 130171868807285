import React from "react";
import * as graphql from "generated/graphql";
import { SubscriptionResult } from "@apollo/client";
import { initialMachineState, MachineState, CallActionType } from "ui/common/hooks/useCallStateMachine";

// TODO: structure flavour
export enum Flavour {
  B2B = "b2b",
  B2C = "b2c",
  Korian = "korian",
  Redurisk = "redurisk",
  Caregiver = "caregiver",
  B2BBasic = "b2bBasic",
}

export enum SaveToGallery {
  Yes,
  No,
}

export type File = {
  id: string;
  kind: graphql.AttachmentKind;
  url: string;
  saveToGallery: SaveToGallery;
  state: "resolving" | "downloaded" | "failed";
  uri?: string;
};

export type DownloadContextType = {
  files: File[];
  resolveUri: (id: string, kind: graphql.AttachmentKind, url: string, saveToGallery: SaveToGallery) => void;
};

export const DownloadContext = React.createContext<DownloadContextType>({
  files: [],
  resolveUri: () => {},
});

type MessageQueueType = {
  addMessage(message: graphql.FullMessageFragment): void;
};

export const MessageQueueContext = React.createContext<MessageQueueType>({
  addMessage() {
    throw Error("No active context provider");
  },
});

export type CallContextType = {
  machine: MachineState;
  call(chatId: string, callerId?: string): void;
  accept(): void;
  cancel(): void;
  reject(): void;
  hangup(): void;
  close(): void;
  timeout(): void;
  error(): void;
  // Get last remote event after app woke up from a push notification
  syncLastEvent(type: CallActionType, chatId: string, callerId?: string): void;
  subscribe(subscriptionData: SubscriptionResult<graphql.OnVideoCallEventSubscription>): void;
};

export const CallContext = React.createContext<CallContextType>({
  machine: initialMachineState,
  call: () => {},
  accept: () => {},
  cancel: () => {},
  reject: () => {},
  hangup: () => {},
  close: () => {},
  timeout: () => {},
  error: () => {},
  syncLastEvent: () => {},
  subscribe: () => {},
});

export enum Region {
  Germany = "Germany",
  Austria = "Austria",
}

export const PictureContext = React.createContext<{
  zoom: boolean;
  setZoom: React.Dispatch<React.SetStateAction<boolean>>;
}>({ zoom: false, setZoom: () => {} });
