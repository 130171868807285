import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18next from "i18next";
import { NotificationCloseButton } from "ui/family/components/NotificationCloseButton";
import { useCommonAppSelector } from "ui/common/state/store";
import { env } from "environment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CloseButtonType = (props: any) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;

export const useNotification = (): {
  showInfo: (msg: string) => void;
  showError: (msg: string) => void;
} => {
  const { t } = useTranslation();
  const { userId } = useCommonAppSelector();

  const showError = (msg: string): void => {
    toast.error(i18next.exists(`toast.${msg}`) ? t(`toast.${msg}`) : msg, {
      closeButton: env.APP_KIND === "family" ? (NotificationCloseButton as CloseButtonType) : true, // FIXME: typeassertion
      autoClose: false,
      className: "error-toast toast-inner",
    });
  };

  const showInfo = (msg: string): void => {
    toast.info(i18next.exists(`toast.${msg}`) ? t(`toast.${msg}`) : msg, {
      closeButton: env.APP_KIND === "family" ? (NotificationCloseButton as CloseButtonType) : true, // FIXME: typeassertion
      className: "info-toast toast-inner",
    });
  };

  return {
    showInfo: useCallback(showInfo, []),
    showError: useCallback(showError, []),
  };
};
