export enum Color {
  primary = "#087BC4",
  primary40 = "#6bc2f9",
  secondaryFFF = "#FFF",
  secondary70 = "#24272B",
  secondary60 = "#47505E",
  secondary50 = "#767B84",
  secondary40 = "#A8ADB8",
  secondary30 = "#D1D3D9",
  secondary20 = "#EEEFF1",
  secondary10 = "#F6F7F8",
  statusRed = "#F25F5F",
  statusGreen = "#0E9C3F",
  background = "#D1D3D9",
  background2 = "#F3F5F6",
  borderGrey = "#C4C4C4",
  translucentBlack = "rgba(0, 0, 0, 0.2)",
  transparent = "transparent",
  linkBlue = "#3880ff",
  softOrange = "#EE9E71",
  desaturatedBlue = "#8C7CBD",
  limeGreen = "#56B677",
  softOrangeTwo = "#DDA646",
}
