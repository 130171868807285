export enum Color {
  primary = "#087BC4",
  primary40 = "#6bc2f9",
  secondaryFFF = "#FFF",
  secondary70 = "#24272B",
  secondary60 = "#47505E",
  secondary50 = "#767B84",
  secondary40 = "#A8ADB8",
  secondary30 = "#D1D3D9",
  secondary20 = "#EEEFF1",
  secondary10 = "#F6F7F8",
  statusRed = "#FF6161",
  statusGreen = "#16B296",
  background = "#FBFBFB",
}
