import React from "react";
import styled from "styled-components";
import { closeIcon } from "./svgIcons";

export const NotificationCloseButton: React.FC<{ closeToast: () => void }> = ({ closeToast }) => (
  <IconWrapper role="button" tabIndex={0} onClick={closeToast} onKeyDown={closeToast}>
    {closeIcon}
  </IconWrapper>
);

const IconWrapper = styled.span`
  margin: auto;
  margin-right: 12px;
`;
