import React from "react";
import { IonButton, IonContent, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { env, AppEnvironments } from "environment";

type ErrorContainerProps = {
  message: string;
  stack: string;
  canGoBack?: boolean;
  onDismiss(): void;
};

export const ErrorContainer: React.FC<ErrorContainerProps> = ({ message, stack, canGoBack, onDismiss }) => {
  const { t } = useTranslation();

  return (
    <IonContent fullscreen>
      <ErrorContent>
        <IonText color="danger">
          <ErrorTitle>{t("crash.title")}</ErrorTitle>
        </IonText>

        <p>
          {t("crash.detail")}
          <br />
          {t("crash.restart-instructions")}
        </p>
        <p>
          {t("crash.contact-info1")}
          <br />
          {t("crash.contact-info2")}
        </p>

        <IonButton id="error_dismiss" onClick={onDismiss} data-track={JSON.stringify({ message })}>
          {t(canGoBack ? "page.back" : "crash.restart")}
        </IonButton>
        {env.ENVIRONMENT !== AppEnvironments.Prod && (
          <DevelopmentInfo>
            <p>Error: {message}</p>
            <p>Component Stack:</p>
            <IonText color="danger">
              <pre>{stack}</pre>
            </IonText>
          </DevelopmentInfo>
        )}
      </ErrorContent>
    </IonContent>
  );
};

const ErrorContent = styled.div`
  text-align: center;
  padding: 2em;
`;

const DevelopmentInfo = styled.div`
  text-align: left;
`;

const ErrorTitle = styled.h2`
  font-size: 2rem;
`;
