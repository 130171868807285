import { CommonState, initialCommonState } from "ui/common/state/appSlice";
import { getUserFromToken } from "ui/common/lib/utils";
import { Storage } from "ui/common/types/storage";
import { captureException } from "ui/common/lib/error_handling";
import { Platform } from "../types/platform";
import { env } from "environment";
import { File } from "context";

// store keys
const TOKEN_KEY = "access_token";
const E2EE_TEST_KEY = "e2ee_test_result";
const FILES_DOWNLOAD_KEY = "files-downloaded";

export class LegacyCommonStorage {
  constructor(private readonly storage: Storage, private readonly platform: Platform) {}

  public async read(): Promise<CommonState> {
    const token = await this.getToken();
    const userId = this.getUserId(token);
    const supportsEncryption = await this.getSupportsEncryption();
    const storedFiles = await this.getStoredFileUris();

    return {
      userId,
      token,
      /*
          NOTE: migration for e2ee keys is not necessary as
          the feature is not released yet to production
        */
      keyPair: undefined,
      senderKeys: {},
      supportsEncryption,
      storedFileUris: storedFiles,
    };
  }

  private getUserId(token: string | null): string | null {
    try {
      return token ? getUserFromToken(token).id : null;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  private async getToken(): Promise<string | null> {
    return (await this.storage.getItem(TOKEN_KEY)) || initialCommonState.token;
  }

  private async getSupportsEncryption(): Promise<boolean | undefined> {
    const value = await this.storage.getItem(E2EE_TEST_KEY);
    return (value && JSON.parse(value)) || undefined;
  }

  private async getFilesDownloadKey(): Promise<string> {
    // FIXME when updating ios, somehow the id of the folder changes. Investigate!
    // This is just a workaround so the file is regenerated for each version
    return this.platform === "ios" ? `${FILES_DOWNLOAD_KEY}${env.RELEASE}` : `${FILES_DOWNLOAD_KEY}`;
  }

  private async getStoredFileUris(): Promise<Record<string, File>> {
    let storedUriList: Record<string, File> = {};

    const value = await this.storage.getItem(await this.getFilesDownloadKey());
    if (value) {
      storedUriList = JSON.parse(value);
    }

    return storedUriList;
  }
}
