import * as Sentry from "@sentry/browser";
import { env } from "environment";
import { logError } from "ui/common/lib/utils";

export enum ErrorType {
  PayloadError = "PayloadError",
  ValueError = "ValueError",
  TypeError = "TypeError",
  ResponseError = "ResponseError",
  DebugError = "DebugError",
  PermissionError = "PermissionError",
  IntegrityError = "IntegrityError",
  UnknownError = "UnknownError",
  NotFoundError = "NotFoundError",
}

// FIXME remove linter exception
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorInfo = Record<string, any>;
export class CustomError extends Error {
  public info: ErrorInfo | undefined;

  public name: ErrorType;

  constructor(name: ErrorType, message: string, info?: ErrorInfo) {
    super(message); // 'Error' breaks prototype chain here
    this.name = name;
    this.info = info;
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

// FIXME remove linter exception
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeMaybeError = (err: any): Error => {
  if (err instanceof Error) return err;
  if ("error" in err && err.error instanceof Error) return err.error;
  if ("originalError" in err && err.originalError instanceof Error) return err.originalError;
  return new Error(err.message || `unexpected error object: ${JSON.stringify(err)}`);
};

export const captureException = (e: unknown, extra?: ErrorInfo): void => {
  const err = normalizeMaybeError(e);
  Sentry.withScope(scope => {
    if (extra) scope.setContext("context", extra);
    Sentry.captureException(err);
    if (!env.SENTRY_DSN || env.SENTRY_DSN === "not_used") logError(err.message, extra);
  });
};
