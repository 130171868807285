import { CustomError, ErrorType } from "ui/common/lib/error_handling";
import * as semver from "semver";

type EnvironmentConfig = {
  API_URL: string;
  WS_URL: string;
  APP_KIND: string;
  ENVIRONMENT: AppEnvironments;
  SENTRY_DSN: string;
  RELEASE: string;
  FLAVOUR?: string;
  SIGNING: string;
  SW_MODE: string;
};

export enum AppEnvironments {
  Local = "local",
  Dev = "dev",
  Stage = "stage",
  Prod = "prod",
}

const isEnvironment = (x?: string): x is AppEnvironments => {
  return (
    !!x &&
    Object.values(AppEnvironments)
      .map(val => val.toString())
      .includes(x)
  );
};

const containsDate = (version: string): boolean => {
  // Ex. master-2020-08-03-1596439083-d54dbf72 => 2020-08-03
  const dateExp = /\d{4}-\d{2}-\d{2}/;
  const date = dateExp.exec(version);
  return Boolean(date?.[0]);
};

const validateAppVersion = (version: string, env: AppEnvironments): void => {
  const isValid =
    env === AppEnvironments.Stage || env === AppEnvironments.Prod ? !!semver.valid(version) : containsDate(version);
  if (!isValid) throw new Error(`Invalid version: ${version} for env: ${env}`);
};

export const setEnvVars = (): EnvironmentConfig => {
  const ENVIRONMENT = process.env.REACT_APP_ENV;
  if (!isEnvironment(ENVIRONMENT)) {
    const msg = `Unknown ENVIRONMENT: ${ENVIRONMENT}`;
    // eslint-disable-next-line no-alert
    window.alert(msg);
    throw new CustomError(ErrorType.ValueError, msg);
  }

  validateAppVersion(process.env.REACT_APP_RELEASE || "", ENVIRONMENT);

  const env: EnvironmentConfig = {
    API_URL: process.env.REACT_APP_API_URL ?? "",
    WS_URL: process.env.REACT_APP_WS_URL ?? "",
    APP_KIND: process.env.REACT_APP_KIND ?? "",
    ENVIRONMENT,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN ?? "",
    RELEASE: process.env.REACT_APP_RELEASE ?? "",
    FLAVOUR: process.env.REACT_APP_FLAVOUR,
    SIGNING: process.env.REACT_APP_SIGNING ?? "",
    SW_MODE: process.env.REACT_APP_SW_MODE ?? "",
  };

  // Check if any of the fields is empty (except for FLAVOUR)
  const missing = Object.keys(env).filter(k => !((env as Record<string, string>)[k] || k === "FLAVOUR"));
  if (missing.length) {
    const msg = `Env vars not found: ${missing.join(" ")}`;
    // eslint-disable-next-line no-alert
    window.alert(msg);
    throw new CustomError(ErrorType.TypeError, msg, { env: process.env });
  }

  return env;
};

export const env = setEnvVars();
