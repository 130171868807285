import { useIdleTimer } from "react-idle-timer";
import { AnalyticsEventName, trackEvent } from "ui/common/lib/analytics";
import { useAppState } from "ui/common/hooks/appState";
import { AppState } from "@capacitor/core";

const SESSION_TIMEOUT = 1000 * 60 * 15;

export const useTrackSession = (): void => {
  const { resume, pause, getLastIdleTime } = useIdleTimer({
    timeout: SESSION_TIMEOUT,
    onActive() {
      trackEvent({ name: AnalyticsEventName.SessionStarted });
    },
    onIdle() {
      trackEvent({ name: AnalyticsEventName.SessionEnded });
    },
  });

  useAppState((state: AppState) => {
    if (state.isActive) {
      resume();
      // onActive only triggers after going idle
      if (!getLastIdleTime()) trackEvent({ name: AnalyticsEventName.SessionStarted });
    } else {
      pause();
      trackEvent({ name: AnalyticsEventName.SessionEnded });
    }
  });
};
