import { Capacitor, Plugins, FilesystemDirectory } from "@capacitor/core";

import { PathParser } from "ui/common/types/fileSystem";

export class CapacitorPathParser implements PathParser {
  async getUriFromPath(path: string): Promise<{ uri: string }> {
    return Plugins.Filesystem.getUri({
      directory: FilesystemDirectory.Data,
      path,
    });
  }

  convertFileSrc(filePath: string): string {
    return Capacitor.convertFileSrc(filePath);
  }
}
