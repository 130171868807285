import { Capacitor } from "@capacitor/core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvailableCmsEnvironments } from "config";
import { Region, Flavour } from "context";
import { env, AppEnvironments } from "environment";
import { PathParser } from "ui/common/types/fileSystem";

export type ContentUpdateStatus = "idle" | "ready" | "inprogress";

export enum ContentModes {
  draft = "draft",
  published = "published",
  beta = "beta",
  release = "release",
}

export enum UsageMode {
  online = "online",
  offline = "offline",
}

export type NameSetupStatus = "inprogress" | "done" | undefined;

export type SettingConfig = {
  password: {
    lastAskedOn: number | undefined;
    ignoreDuration: TimeInMinutes | undefined;
    value: string | undefined;
  };
  enabled: boolean;
};

type TimeInMinutes = number;

export type SettingOptions = "appSettings" | "automaticVideoCall" | "internet" | "videoVisit";

export const SettingOptionDefaults: { [key in SettingOptions]: boolean } = {
  appSettings: true,
  automaticVideoCall: false,
  internet: false,
  videoVisit: true,
};

export type SettingsConfig = { [key in SettingOptions]: SettingConfig };
export interface SeniorState {
  setupCompleted: boolean;
  region: Region;
  contentUpdateStatus: ContentUpdateStatus;
  showWalkthrough: boolean;
  softwareOnly: boolean;
  tabletCompatible: boolean;
  flavour: Flavour | null;
  cmsEnvironment: AvailableCmsEnvironments;
  isBusy: boolean;
  showLicenseReminder: boolean;
  usageMode: UsageMode;
  contentGroup: string | null;
  contentMode: ContentModes;
  contentGroupSetupStatus: "complete" | undefined;
  installedContentVersion: number | undefined;
  selectedContentVersionInEasterEgg: number | undefined;
  overrideLicensing: boolean;
  skipSdCard: boolean;
  nameSetupStatus: NameSetupStatus;
  legalPromptShown: boolean;
  storedVersion: string | undefined;
  trialStartedOn: string | undefined;
  licenseCheckedOn: string | undefined;
  contentReminderLastCheckedOn?: string;
  existingUserLicenseActivationInProgress: boolean;
  settingsConfig: SettingsConfig;
}

const getDefaultSettingConfig = (option: SettingOptions): SettingConfig => {
  return {
    password: {
      lastAskedOn: undefined,
      ignoreDuration: undefined,
      value: undefined,
    },
    enabled: SettingOptionDefaults[option],
  };
};

// FIXME: Inline and remove dep injection of parser in store
export const getMediaPath = async (usageMode: UsageMode, parser: PathParser): Promise<string | null> => {
  if (usageMode === UsageMode.offline) {
    const { uri } = await parser.getUriFromPath("/");
    return parser.convertFileSrc(uri);
  }
  return null;
};

// FIXME: remove dependency of appSlice on Capacitor
export const initialSeniorState: SeniorState = {
  setupCompleted: Capacitor.getPlatform() !== "android",
  region: Region.Germany,
  contentUpdateStatus: "idle" as ContentUpdateStatus,
  showWalkthrough: false,
  softwareOnly: env.SW_MODE === "swOnly",
  tabletCompatible: false,
  flavour: (env.FLAVOUR && (env.FLAVOUR as Flavour)) || null,
  cmsEnvironment: AppEnvironments.Prod,
  isBusy: false,
  showLicenseReminder: false,
  usageMode:
    (env.ENVIRONMENT === AppEnvironments.Prod || env.ENVIRONMENT === AppEnvironments.Stage) && Capacitor.isNative
      ? UsageMode.offline
      : UsageMode.online,
  contentGroup: null,
  contentMode: env.ENVIRONMENT === AppEnvironments.Prod ? ContentModes.release : ContentModes.beta,
  contentGroupSetupStatus: undefined,
  installedContentVersion: undefined,
  selectedContentVersionInEasterEgg: undefined,
  skipSdCard: false,
  overrideLicensing: false,
  nameSetupStatus: undefined,
  legalPromptShown: false,
  storedVersion: undefined,
  trialStartedOn: undefined,
  licenseCheckedOn: undefined,
  contentReminderLastCheckedOn: undefined,
  existingUserLicenseActivationInProgress: false,
  settingsConfig: {
    appSettings: getDefaultSettingConfig("appSettings"),
    automaticVideoCall: getDefaultSettingConfig("automaticVideoCall"),
    internet: getDefaultSettingConfig("internet"),
    videoVisit: getDefaultSettingConfig("videoVisit"),
  },
};

export const seniorAppSlice = createSlice({
  name: "app",
  initialState: initialSeniorState,
  reducers: {
    setIsBusy: (state, action: PayloadAction<boolean>) => {
      state.isBusy = action.payload;
    },
    setShowLicenseReminder: (state, action) => {
      state.showLicenseReminder = action.payload;
    },
    completeSetup(state) {
      state.setupCompleted = true;
    },
    setRegion(state, action: PayloadAction<Region>) {
      state.region = action.payload;
      state.contentUpdateStatus = "ready";
    },
    setContentUpdateStatus(state, action: PayloadAction<ContentUpdateStatus>) {
      state.contentUpdateStatus = action.payload;
    },
    showWalkthrough(state, action: PayloadAction<boolean>) {
      state.showWalkthrough = action.payload;
    },
    setTabletCompatible(state) {
      state.tabletCompatible = true;
    },
    setFlavour(state, action: PayloadAction<Flavour>) {
      state.flavour = action.payload;
    },
    setCmsEnvironment(state, action: PayloadAction<AvailableCmsEnvironments>) {
      state.cmsEnvironment = action.payload;
    },
    setContentGroup(state, action: PayloadAction<string>) {
      state.contentGroup = action.payload;
    },
    setContentMode(state, action: PayloadAction<ContentModes>) {
      state.contentMode = action.payload;
    },
    setUsageMode(state, action: PayloadAction<UsageMode>) {
      state.usageMode = action.payload;
    },
    setContentGroupSetupStatus(state, action: PayloadAction<"complete" | undefined>) {
      state.contentGroupSetupStatus = action.payload;
    },
    setInstalledContentVersion(state, action: PayloadAction<number | undefined>) {
      state.installedContentVersion = action.payload;
    },
    setSelectedContentVersionInEasterEgg(state, action: PayloadAction<number | undefined>) {
      state.selectedContentVersionInEasterEgg = action.payload;
    },
    setSkipSdCard(state) {
      state.skipSdCard = true;
    },
    setNameSetupStatus(state, action: PayloadAction<NameSetupStatus>) {
      state.nameSetupStatus = action.payload;
    },
    setOverrideLicensing(state, action: PayloadAction<boolean>) {
      state.overrideLicensing = action.payload;
    },
    setLegalPromptShown(state, action: PayloadAction<boolean>) {
      state.legalPromptShown = action.payload;
    },
    setStoredVersion(state, action: PayloadAction<string>) {
      state.storedVersion = action.payload;
    },
    setTrialStartedOn(state, action: PayloadAction<string>) {
      state.trialStartedOn = action.payload;
    },
    setLicenseCheckedOn(state, action: PayloadAction<string>) {
      state.licenseCheckedOn = action.payload;
    },
    setContentReminderLastCheckedOn(state, action: PayloadAction<string>) {
      state.contentReminderLastCheckedOn = action.payload;
    },
    setLastTimeAskedPasswordForSetting(state, action: PayloadAction<{ settingOption: SettingOptions; time: number }>) {
      state.settingsConfig[action.payload.settingOption].password.lastAskedOn = action.payload.time;
    },
    setIgnorePasswordForSetting(
      state,
      action: PayloadAction<{ settingOption: SettingOptions; ignoreDuration: TimeInMinutes }>
    ) {
      state.settingsConfig[action.payload.settingOption].password.ignoreDuration = action.payload.ignoreDuration;
    },
    setPasswordForSetting(state, action: PayloadAction<{ settingOption: SettingOptions; password: string }>) {
      state.settingsConfig[action.payload.settingOption].password.value = action.payload.password;
    },
    enableSetting(state, action: PayloadAction<{ settingOption: SettingOptions; enabled: boolean }>) {
      state.settingsConfig[action.payload.settingOption].enabled = action.payload.enabled;
    },
    setExistingUserLicenseActivationInProgress(state, action: PayloadAction<boolean>) {
      state.existingUserLicenseActivationInProgress = action.payload;
    },
    resetSeniorState: () => {
      return { ...initialSeniorState };
    },
  },
});

export const {
  setIsBusy,
  setShowLicenseReminder,
  setContentMode,
  setContentGroup,
  setCmsEnvironment,
  setFlavour,
  setTabletCompatible,
  showWalkthrough,
  setContentUpdateStatus,
  setRegion,
  setContentGroupSetupStatus,
  completeSetup,
  setInstalledContentVersion,
  setSelectedContentVersionInEasterEgg,
  setSkipSdCard,
  setNameSetupStatus,
  setOverrideLicensing,
  setLegalPromptShown,
  setStoredVersion,
  setTrialStartedOn,
  setLicenseCheckedOn,
  setContentReminderLastCheckedOn,
  setLastTimeAskedPasswordForSetting,
  setIgnorePasswordForSetting,
  setUsageMode,
  setPasswordForSetting,
  enableSetting,
  resetSeniorState,
  setExistingUserLicenseActivationInProgress,
} = seniorAppSlice.actions;
