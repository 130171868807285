import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { File } from "context";
import { getUserFromToken } from "../lib/utils";
import { Storage } from "../types/storage";

export type KeyPair = {
  publicKey: string;
  privateKey: string;
};

export type SenderKey = {
  id: string;
  secretKey: string;
  secureIV: string;
};

export interface CommonState {
  userId: string | null;
  token: string | null;
  keyPair: KeyPair | undefined;
  senderKeys: Record<string, SenderKey>;
  supportsEncryption: boolean | undefined;
  storedFileUris: Record<string, File>;
}

export const initialCommonState: CommonState = {
  userId: null,
  token: null,
  keyPair: undefined,
  senderKeys: {},
  supportsEncryption: undefined,
  storedFileUris: {},
};

export const clearRemoteDataCache = createAsyncThunk<void, undefined, { extra: { storage: Storage } }>(
  "common/clearRemoteDataCache",
  async (_, { extra: { storage } }) => {
    storage.removeItem("apollo-cache-persist");
  }
);

export const commonAppSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {
    setUserId(state, action: PayloadAction<string | null>) {
      state.userId = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      const token = action.payload;
      if (token) {
        state.token = token;
        state.userId = getUserFromToken(token).id;
      } else {
        state.token = null;
        state.userId = null;
      }
    },
    setKeyPair(state, action: PayloadAction<KeyPair>) {
      state.keyPair = action.payload;
    },
    setSenderKey(state, action: PayloadAction<SenderKey>) {
      state.senderKeys[action.payload.id] = action.payload;
    },
    setSupportsEncryption(state, action: PayloadAction<boolean>) {
      state.supportsEncryption = action.payload;
    },
    storeFileUri(state, action: PayloadAction<File>) {
      state.storedFileUris[action.payload.id] = action.payload;
    },
    resetCommonState() {
      return { ...initialCommonState };
    },
  },
});

export const {
  setToken,
  setUserId,
  setKeyPair,
  setSenderKey,
  setSupportsEncryption,
  storeFileUri,
  resetCommonState,
} = commonAppSlice.actions;
