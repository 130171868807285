import React, { useEffect, Suspense, lazy, useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { ApolloProvider, InMemoryCache } from "@apollo/client";
import { env } from "environment";
import { AppErrorBoundary } from "ui/common/components/AppErrorBoundary";
import { toast } from "react-toastify";
import { Capacitor, Plugins } from "@capacitor/core";
import { CustomError } from "ui/common/lib/error_handling";
import { useAppDispatch } from "ui/common/state/store";
import { setToken } from "ui/common/state/appSlice";
import { useApolloClientInit } from "ui/common/lib/client";
import { useNotification } from "ui/common/hooks/useNotification";
import { useTrackSession } from "ui/common/hooks/useTrackSession";

/* Core CSS required for Ionic components to work properly */
import "./fonts.css";
import "./globals.scss";
import "react-gallery-carousel/dist/index.css";

const { Media4CarePlugin } = Plugins;

toast.configure();
if (["dev", "stage", "prod"].includes(env.ENVIRONMENT)) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.ENVIRONMENT,
    release: env.RELEASE,
    sampleRate: 0.25,
    beforeSend: (event, hint) => {
      if (hint?.originalException instanceof CustomError) {
        // eslint-disable-next-line no-param-reassign
        event.contexts = { context: { ...hint.originalException.info } };
      }
      return event;
    },
  });
}

const AppRoute = lazy(() => {
  switch (env.APP_KIND) {
    case "senior":
      return import("ui/senior/routes");
    case "family":
      return import("ui/family/routes");
    case "admin":
      return import("ui/admin/routes");
    default:
      throw Error("Unknown app");
  }
});

const App = React.memo<AppProps>(({ cache }) => {
  const { showInfo } = useNotification();
  const dispatch = useAppDispatch();
  useTrackSession();

  const onTokenError = useCallback((): void => {
    if (Capacitor.platform === "android") Media4CarePlugin.stopCatapush();
    dispatch(setToken(null));
    showInfo("logout-success");
  }, [dispatch, showInfo]);

  const client = useApolloClientInit(cache, onTokenError);

  useEffect(() => {
    switch (env.APP_KIND) {
      case "senior":
        document.title = "Media4Care";
        break;
      case "family":
        document.title = "Familienportal";
        break;
      case "admin":
        document.title = "Media4Care Lizenzportal";
        break;
      default:
        document.title = "Media4Care";
        break;
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={null}>
        <AppErrorBoundary>
          <AppRoute />
        </AppErrorBoundary>
      </Suspense>
    </ApolloProvider>
  );
});

type AppProps = {
  cache: InMemoryCache;
};

export default App;
