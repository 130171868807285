import { Storage as capacitorStorage } from "./storage";
import { Storage } from "ui/common/types/storage";

export class CapacitorStorageAdapter implements Storage {
  async getItem(key: string): Promise<string | null> {
    const { value } = await capacitorStorage.get({ key });

    return value || null;
  }
  async setItem(key: string, value: string): Promise<void> {
    await capacitorStorage.set({ key, value });
  }
  async removeItem(key: string): Promise<void> {
    await capacitorStorage.remove({ key });
  }
}
