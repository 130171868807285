import { env } from "environment";
import {
  useAppDispatch as useAppDispatchFromSeniorStore,
  useAppSelector as useAppSelectorFromSeniorStore,
} from "ui/senior/state/store";
import {
  useAppDispatch as useAppDispatchFromFamilyStore,
  useAppSelector as useAppSelectorFromFamilyStore,
} from "ui/family/state/store";
import { CommonState } from "./appSlice";

export const useCommonAppSelector = (): CommonState => {
  const selector = env.APP_KIND === "senior" ? useAppSelectorFromSeniorStore : useAppSelectorFromFamilyStore;
  return selector(state => state.common);
};

export const useAppDispatch = env.APP_KIND === "senior" ? useAppDispatchFromSeniorStore : useAppDispatchFromFamilyStore;
