import React, { ErrorInfo } from "react";
import { captureException } from "ui/common/lib/error_handling";
import { ErrorContainer } from "./ErrorContainer";

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export class AppErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // FIXME Use ErrorBoundary from @sentry/react package
    captureException(error);
    this.setState({ error, errorInfo });
  }

  render(): React.ReactNode {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (error && errorInfo) {
      return (
        <ErrorContainer
          message={error.message}
          stack={errorInfo.componentStack}
          onDismiss={() => window.location.reload()}
        />
      );
    }

    return children;
  }
}
