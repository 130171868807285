import { useMemo } from "react";
import { ApolloClient, QueryResult, HttpLink, from, split, makeVar, ApolloError } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject, Reference, StoreObject } from "@apollo/client/cache";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { ExecutionResult } from "graphql";
import { persistCache } from "apollo3-cache-persist";
import { env } from "environment";
import * as graphql from "generated/graphql";
import { captureException, CustomError, ErrorType } from "ui/common/lib/error_handling";
import { useCommonAppSelector } from "ui/common/state/store";
import { Storage } from "../types/storage";

interface BatteryStatusResponse {
  level: number;
  isPlugged: boolean;
}

export const batteryStatusResponse = makeVar<BatteryStatusResponse | null>(null);

type NodePage = {
  nodes?: Array<Reference | StoreObject | undefined>;
  pageInfo: object;
};

export const tokenErrors = ["jwt expired", "jwt malformed", "invalid signature", "user-not-found"];

export const initApolloCache = async (storage: Storage): Promise<InMemoryCache> => {
  /* Init Cache */
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          unreadMessagesList: {
            merge: false,
          },
          getSystemMessagesList: {
            keyArgs: false,
            merge(existing: Reference[], incoming: Reference[], { readField }) {
              const merged = existing ? existing.slice(0) : [];
              if (incoming)
                incoming.forEach(item => {
                  if (!merged.find(m => readField("id", m) === readField("id", item))) {
                    merged.push(item);
                  }
                });

              return merged;
            },
            read(collection: Reference[], { readField }) {
              const sorted = [...(collection || [])].sort((first, second) => {
                const firstCreatedAt = String(readField("createdAt", first));
                const secondCreatedAt = String(readField("createdAt", second));

                return new Date(firstCreatedAt).getTime() - new Date(secondCreatedAt).getTime();
              });
              return sorted;
            },
          },
        },
      },
      Group: {
        fields: {
          supportedFeatures(current = []) {
            return current;
          },
          memberDevices(current = []) {
            return current;
          },
          senderKeysList(current = []) {
            return current;
          },
          relatedSeniorEncryptionMode(current = graphql.EncryptionMode.None) {
            return current;
          },
          messages: {
            keyArgs: false,
            merge(existing: NodePage, incoming: NodePage, { readField }) {
              const merged = existing?.nodes ? existing.nodes.slice(0) : [];
              if (incoming?.nodes)
                incoming.nodes.forEach(item => {
                  if (!merged.find(m => readField("id", m) === readField("id", item))) {
                    merged.push(item);
                  }
                });

              return {
                ...incoming,
                nodes: merged,
              };
            },
            read(collection: NodePage, { readField }) {
              const sorted = [...(collection?.nodes || [])].sort((first, second) => {
                const firstCreatedAt = String(readField("createdAt", first));
                const secondCreatedAt = String(readField("createdAt", second));

                return new Date(firstCreatedAt).getTime() - new Date(secondCreatedAt).getTime();
              });
              return {
                ...collection,
                nodes: sorted,
              };
            },
          },
        },
      },
      Attachment: {
        fields: {
          status(current) {
            // For attachments created before this field was introduced.
            return current || graphql.AttachmentStatus.Uploaded;
          },
          duration(current = 0) {
            return current;
          },
        },
      },
      Invitation: {
        fields: {
          invitedTo(current = "") {
            return current;
          },
        },
      },
      SystemMessage: {
        fields: {
          status(current) {
            return current || graphql.SystemMessageStatus.Unseen;
          },
        },
      },
      Message: {
        fields: {
          senderKeyId(current = null) {
            return current;
          },
        },
      },
    },
  });

  await persistCache({
    cache,
    storage,
  });

  return cache;
};

export const useApolloClientInit = (
  cache: InMemoryCache,
  onTokenError: () => void
): ApolloClient<NormalizedCacheObject> => {
  const { token } = useCommonAppSelector();
  return useMemo(
    () =>
      new ApolloClient({
        link: split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === "OperationDefinition" && definition.operation === "subscription";
          },
          new WebSocketLink({
            uri: env.WS_URL,
            options: {
              reconnect: true,
              lazy: true,
              async connectionParams() {
                return { authorization: `Bearer ${token}` };
              },
            },
          }),
          from([
            setContext(async (_, { headers, noHeader }) => {
              return token && !(headers && "Authorization" in headers) && !noHeader
                ? { headers: { ...headers, Authorization: `Bearer ${token}` } }
                : { headers };
            }),
            new RetryLink({
              delay: {
                initial: 300,
                max: 1000,
                jitter: true,
              },
              attempts: (_count, operation) => ["CreateMessage", "markAsRead"].includes(operation.operationName),
            }),
            onError(({ graphQLErrors }) => {
              if (graphQLErrors && tokenErrors.includes(graphQLErrors[0].message)) {
                onTokenError();
              }
            }),
            new HttpLink({ uri: env.API_URL }),
          ])
        ),
        cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-only",
          },
        },
      }),
    [cache, onTokenError, token]
  );
};

export const handleApiError = (
  error: unknown,
  showError: (errorMsg: string) => void,
  options?: {
    networkErrorMessage?: string;
    authErrorMessage?: string;
    offlineErrorMessage?: string;
  }
): void => {
  let msg = "";
  if (error instanceof ApolloError && error.graphQLErrors && error.graphQLErrors.length) {
    captureException(error);
    msg = error.graphQLErrors[0].message;
  } else if (error instanceof ApolloError && error.networkError) {
    // NOTE: only show network error if message in options is set
    if (options) {
      msg = options.networkErrorMessage || msg;
      if (error.networkError.message.includes("401") || error.networkError.message.includes("403")) {
        msg = options.authErrorMessage || msg;
      } else if (error.networkError.message.includes("Failed to fetch")) {
        msg = options.offlineErrorMessage || "network-offline";
      }
    }
  } else {
    throw error;
  }
  if (msg) showError(msg);
};

export const throwResponseError = <R>(response: QueryResult<R> | ExecutionResult<R>): void => {
  throw new CustomError(ErrorType.ResponseError, "Unexpected response", { response });
};

export const handleMutation = async <R>(
  pro: Promise<ExecutionResult<R>>,
  process: (response: ExecutionResult<R>) => boolean,
  showError: (err: string) => void,
  options?: {
    networkErrorMessage?: string;
    authErrorMessage?: string;
  }
): Promise<void> => {
  try {
    const response = await pro;
    if (!process(response)) {
      throwResponseError(response);
    }
  } catch (error) {
    // handleApiError will log to Sentry
    handleApiError(error, showError, options);
  }
};
