import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { AppEnvironments, env } from "environment";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { CommonState, commonAppSlice } from "ui/common/state/appSlice";
import { seniorAppSlice, SeniorState } from "./appSlice";
import { PathParser } from "ui/common/types/fileSystem";
import { Storage } from "ui/common/types/storage";

export type AppState = {
  app: SeniorState;
  common: CommonState;
};

export const PERSISTOR_KEY = "senior";

const rootReducer = combineReducers({ app: seniorAppSlice.reducer, common: commonAppSlice.reducer });

// FIXME define state type and return type
export const getSeniorStoreAndPersistor = (storage: Storage, parser: PathParser) => {
  const persistConfig = {
    key: PERSISTOR_KEY,
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    devTools: env.ENVIRONMENT === AppEnvironments.Dev || env.ENVIRONMENT === AppEnvironments.Local,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk: {
          extraArgument: {
            parser,
            storage,
          },
        },
      }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

type ConfiguredStore = ReturnType<typeof getSeniorStoreAndPersistor>["store"];
type StoreGetState = ConfiguredStore["getState"];
export type RootState = ReturnType<StoreGetState>;
export type AppDispatch = ConfiguredStore["dispatch"];

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useSeniorAppSelector = (): SeniorState & CommonState =>
  useAppSelector(state => ({ ...state.app, ...state.common }));
