import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locale/en.json";
import de from "./locale/de.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      de,
    },
    detection: {
      order: ["querystring", "navigator", "localStorage", "htmlTag", "path", "subdomain"],
    },
    fallbackLng: "de",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
