import { Capacitor, Plugins } from "@capacitor/core";
import { CapacitorDataStorageSqlite, CapacitorDataStorageSqlitePlugin } from "@jeepq/capacitor";

// From plugin documentation
export const Storage = (Capacitor.isNative
  ? Plugins.CapacitorDataStorageSqlite
  : CapacitorDataStorageSqlite) as CapacitorDataStorageSqlitePlugin;

export const setupStorage = async (): Promise<void> => {
  await Storage.openStore({ database: "media4care", table: "storage" });
};
