import { CommonState } from "ui/common/state/appSlice";
import { Storage } from "ui/common/types/storage";
import { PERSISTOR_KEY } from "./store";

import { FamilyState } from "./appSlice";
import { LegacyFamilyStorage } from "./legacyFamilyStorage";
import { LegacyCommonStorage } from "ui/common/state/legacyCommonStorage";
import { Platform } from "ui/common/types/platform";

// store keys
export const TOKEN_KEY = "access_token";

const REDUX_PERSIST_KEY = `persist:${PERSISTOR_KEY}`;

export class StoreMigrator {
  constructor(private readonly storage: Storage, private readonly platform: Platform) {}

  private async readStateFromLegacyStorage(): Promise<{ app: FamilyState; common: CommonState }> {
    return {
      app: await new LegacyFamilyStorage(this.storage).read(),
      common: await new LegacyCommonStorage(this.storage, this.platform).read(),
    };
  }

  async migrate(): Promise<void> {
    const state = await this.storage.getItem(REDUX_PERSIST_KEY);
    const hasMigratedToReduxPersist = state !== null;

    if (hasMigratedToReduxPersist) {
      return;
    }

    const legacyState = await this.readStateFromLegacyStorage();

    /*
      Why keys are stringified?
      https://github.com/rt2zz/redux-persist/issues/489#issuecomment-336928988
    */
    const reduxPersistState = {
      app: JSON.stringify(legacyState.app),
      common: JSON.stringify(legacyState.common),
    };

    await this.storage.setItem(REDUX_PERSIST_KEY, JSON.stringify(reduxPersistState));
  }
}
